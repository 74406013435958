//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, useContext } from '@nuxtjs/composition-api'
import useCustomerData from '~/composables/useCustomerData'

export default defineComponent({
  name: 'Contact',
  props: {
    headline: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    salutationHeadline: {
      type: String,
      default: null
    },
    salutationText: {
      type: String,
      default: null
    },
    links: {
      type: Array,
      default: null
    },
    buttons: {
      type: Array,
      default: null
    },
    showContactButton: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { $t } = useContext()
    const { userInfo } = useCustomerData()

    const contactData = computed(() => userInfo.value?.company?.contactData || null)
    const effectiveContactData = computed(() => {
      const data = {
        salutationText: props.salutationText,
        links: props.links,
        buttons: props.buttons
      }

      if (contactData.value) {
        data.salutationText = props.salutationText.replace(/\{\{([^}]+)\}\}/g, (_, key) => {
          return contactData.value[key.trim()] ?? key.trim()
        })
        data.links = [
          {
            icon: 'IconPhone',
            label: contactData.value.phone,
            link: `tel:${contactData.value.phone.replace(/[^0-9+]/g, '')}`
          },
          {
            icon: 'IconMail',
            label: contactData.value.email,
            link: `mailto:${contactData.value.email}`
          }
        ]
        data.buttons = [
          {
            component: 'Button',
            label: $t('contact.emailButton'),
            icon: 'IconMail',
            buttonType: 'primary-white',
            link: `mailto:${contactData.value.email}`
          }
        ]
      }
      return data
    })

    return {
      effectiveContactData
    }
  }
})
